import React from "react"
import { Helmet } from "react-helmet"
import { ImageBuilder } from "../../_types/AssetsImage"
import { useLogos } from "../../hooks/queries/settings/useLogos"
import { useGeneralSettings } from "../../hooks/useGeneralSettings"

interface BlogSchemaProps {
  heading: string
  description: string
  publishedDate: string
  image: ImageBuilder
}

export const BlogSchema = ({ heading, description, publishedDate, image }: BlogSchemaProps) => {
  const {companyName} = useGeneralSettings()
  const {headerLogo} = useLogos()
  
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${heading}",
            "description": "${description}",
            "datePublished": "${publishedDate}",
            "mainEntityOfPage": "True",
            "image": {"@type": "imageObject","url": "${image.url}","height": "${image.height}","width": "${image.width}"},
            "publisher": {"@type": "Organization","name": "${companyName}","logo": {"@type": "imageObject","url": "${headerLogo.url}"}}
          }
        `}
      </script>
    </Helmet>
  )
}

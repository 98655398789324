import React from "react"
import { Link } from "gatsby"
import { useWindowDimensions } from "../../../hooks/useWindowDimensions"
import { LeftArrow, RightArrow, LeftChevron, RightChevron } from "./Arrows"
import { Button } from "../../button"
import { BlogPostEdge } from "../BlogBanner/blog-banner-types"
import "./internal-pagination.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

interface InternalPaginationProps {
  prev?: BlogPostEdge['node']
  next?: BlogPostEdge['node']
  parentSlug: string
  isPrevNextEnabled?: boolean
}

export const InternalPagination = ({ prev, next, parentSlug, isPrevNextEnabled = false }: InternalPaginationProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { size } = useWindowDimensions()
  
  const formatParentSlugText = (slug: string) => {
    if (slug.startsWith("company")) {
      let splited = slug.split("/")
      return splited.length > 1
        ? splited[1].charAt(0).toUpperCase() + splited[1].slice(1).toLowerCase()
        : slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase()
    }
    return slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase()
  }

  const withoutNextAndPreviousClassName = isPrevNextEnabled ? "" : "fr-internal-pagination--no-controller-links"

  return (
    <section className={`fr-internal-pagination ${withoutNextAndPreviousClassName}`}>
      {(isPrevNextEnabled && prev) && (
        <Link
          to={`/${linkedPagesUrlPathCodex[prev.elements.slug.value]}`}
          className={`fr-internal-pagination__controller-link`}
        >
          <div className={`fr-internal-pagination__link-info`}>
            <span className={`fr-internal-pagination__previous-btn`}>
              {size >= 1366 && <LeftArrow />}
              {size < 1366 && <LeftChevron />}
              Prev{size >= 768 && "ious"}
            </span>
            {size >= 768 && <hr />}
            {size >= 768 && prev.elements.heading?.value}
          </div>
        </Link>
      )}
      <Button
        variant="outline-secondary"
        to={`/${parentSlug}`}
      >
        View All {formatParentSlugText(parentSlug)}s
      </Button>
      {(isPrevNextEnabled && next) && (
        <Link
          to={`/${linkedPagesUrlPathCodex[next.elements.slug.value]}`}
          className={`fr-internal-pagination__controller-link`}
        >
          <div className={`fr-internal-pagination__link-info`}>
            <span className={`fr-internal-pagination__next-btn`}>
              Next
              {size >= 1366 && <RightArrow />}
              {size < 1366 && <RightChevron />}
            </span>
            {size >= 768 && <hr />}
            {size >= 768 && next.elements.heading?.value}
          </div>
        </Link>
      )}
    </section>
  )
}

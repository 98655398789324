import React, { useEffect, useState } from "react"
import { appendScript } from "../../../assets/helper/appendScript"
import { SkeletonElement } from "../../skeleton/SkeletonElement";
import "./social-share-toolbox.module.scss"

export const SocialShareToolbox = () => {
  const [hasSocialShareScriptAppended, setHasSocialShareScriptAppended] = useState(false);

  useEffect(() => {
    let mounted = true

    setTimeout(
      () => {
        if (mounted) {
          setHasSocialShareScriptAppended(true)
          appendScript({
            id: "social-share",
            scriptToAppend:
              "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-59149efa167e65e2",
          })
        }
      },
      4000
    )

    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className="fr-social-share-toolbox">
      {/* This is for the social share toolbox */}
      <script type="text/javascript" src=""></script>
        <div
          className="addthis_inline_share_toolbox fr-social-share-toolbox__wrapper"
        ></div>
      {/* END */}

      {!hasSocialShareScriptAppended && (
        <div className="fr-social-share-toolbox__skeleton">
        <SkeletonElement type="text" theme="light" additionalClassNames="fr-social-share-toolbox__skeleton-pill fr-social-share-toolbox__skeleton-pill--facebook" />
        <SkeletonElement type="text" theme="light" additionalClassNames="fr-social-share-toolbox__skeleton-pill fr-social-share-toolbox__skeleton-pill--twitter" />
        <SkeletonElement type="text" theme="light" additionalClassNames="fr-social-share-toolbox__skeleton-pill fr-social-share-toolbox__skeleton-pill--email" />
        <SkeletonElement type="text" theme="light" additionalClassNames="fr-social-share-toolbox__skeleton-pill fr-social-share-toolbox__skeleton-pill--more" />
      </div>
      )}
    </div>
  )
}